import {
  BlogLevelAccessRole,
  BlogResponseDto,
  BlogStatus,
} from '@admin/features/api/generated';
import { Link } from '@tanstack/react-router';
import { useTranslatedContent } from '@admin/hooks/useTranslatedContent';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Tag } from '@admin/features/blogs/components/Tag/Tag';
import { Button } from '@admin/features/theme/components/Button/Button';
import { RemoveButton } from '@admin/features/layout/components/RemoveButton/RemoveButton';
import { useHandledMutation } from '@admin/hooks/useHandledMutation';
import { api } from '@admin/features/api';
import { useQueryClient } from '@tanstack/react-query';
import useLazyQuery from '@admin/features/api/useLazyQuery';
import { useBlogsTranslation } from '../../locales/useBlogsTranslation';
import { useBlogPermissions } from './useBlogPermissions';
import { cn } from '@admin/utils/cn';
import env from '@admin/env';
import { useMemo } from 'react';
import { BlogUpdatedDateBadge } from '@admin/features/theme/components/BlogUpdatedDateBadge/BlogUpdatedDateBadge';
import { BlogStatusBadge } from '@admin/features/theme/components/BlogStatusBadge/BlogStatusBadge';
import { BlogVisibilityBadge } from '@admin/features/theme/components/BlogVisibilityBadge/BlogVisibilityBadge';

export type BlogPostListItemProps = {
  allowToApprove?: boolean;
  disableGroupColumn?: boolean;
} & BlogResponseDto;

export const BlogPostListItem: React.FC<BlogPostListItemProps> = ({
  translations,
  tags,
  author,
  id,
  blogGroup,
  allowToApprove,
  status,
  disableGroupColumn,
  publishedAt,
  updatedAt,
  lastUpdatedBy,
  visibility,
}) => {
  const { isEditor, canEdit, canRemove, user, blogGroupAccess } =
    useBlogPermissions(blogGroup);
  const { t } = useBlogsTranslation();
  const translation = useTranslatedContent(translations);
  const queryClient = useQueryClient();

  const [approveBlog, { isLoading: isApproveBlogLoading }] = useLazyQuery(
    ['blogControllerApproveBlog'],
    async () => {
      await api.blogControllerApproveBlog(id);
      await queryClient.invalidateQueries({
        queryKey: ['blogControllerGetSubmittedBlogs'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['blogControllerGetApprovedBlogs'],
      });
    }
  );

  const [rejectBlog, { isLoading: isRejectBlogLoading }] = useLazyQuery(
    ['blogControllerRejectBlog'],
    async () => {
      await api.blogControllerRejectBlog(id);
      await queryClient.invalidateQueries({
        queryKey: ['blogControllerGetSubmittedBlogs'],
      });
    }
  );

  const { mutate, isPending } = useHandledMutation({
    mutationFn: () => api.blogControllerDeleteBlog(id),
    mutationKey: ['blogControllerDeleteBlog'],
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['blogControllerGetBlogsByGroup', blogGroup.id],
      });
    },
  });

  const approveBlogHandler = (): void => {
    approveBlog();
  };

  const rejectBlogHandler = (): void => {
    rejectBlog();
  };

  const publicUrl = useMemo(
    () =>
      new URL(
        `/${translation?.language}/blog/${status === BlogStatus.Published ? translation?.slug : id}`,
        env.landingPageUrl
      ).toString(),
    [id, status, translation?.language, translation?.slug]
  );

  return (
    <div className='grid grid-cols-subgrid col-start-1 -col-end-1 items-center odd:bg-black/[0.03]'>
      <div className='flex flex-col gap-y-2 px-3 py-2'>
        <div className='flex flex-row gap-x-2 items-center'>
          <span>{translation?.title}</span>
          <a href={publicUrl} target='_blank' rel='noopener noreferrer'>
            <FaExternalLinkAlt size={12} />
          </a>
          <BlogStatusBadge status={status} publishedAt={publishedAt} />
          <BlogVisibilityBadge visibility={visibility} />
          {updatedAt && lastUpdatedBy?.fullName && (
            <BlogUpdatedDateBadge
              updatedAt={updatedAt}
              updatedBy={lastUpdatedBy.fullName}
            />
          )}
        </div>
        {tags.length > 0 && (
          <div className='flex flex-row items-center gap-2'>
            {tags.map((t) => (
              <Tag {...t} key={t.id} />
            ))}
          </div>
        )}
      </div>
      <div className='px-3 py-2'>
        <span
          className={cn('text-sm', {
            'text-gray-400': !author,
          })}
        >
          {author ? author.fullName : t('list.author.empty')}
        </span>
      </div>
      {!disableGroupColumn && (
        <div className='px-3 py-2'>
          <span className='text-sm'>{blogGroup.name}</span>
        </div>
      )}
      {(!allowToApprove || (!isEditor && Boolean(allowToApprove))) && (
        <div className='flex flex-wrap gap-2 flex-row items-stretch justify-end px-3 py-2'>
          {allowToApprove && (
            <>
              <Button
                loading={isApproveBlogLoading}
                variant='outline'
                onClick={approveBlogHandler}
              >
                {t('list.item.approve')}
              </Button>
              <Button
                loading={isRejectBlogLoading}
                variant='outline'
                onClick={rejectBlogHandler}
              >
                {t('list.item.reject')}
              </Button>
            </>
          )}
          {(canEdit ||
            (author &&
              author.id === user?.id &&
              (![BlogStatus.Approved, BlogStatus.Published].includes(status) ||
                blogGroupAccess?.blogLevelAccessRole !==
                  BlogLevelAccessRole.BlogLevelOnlyNew))) && (
            <Button as={Link} to={`/blogs/${id}/update-blog`} size='sm'>
              {t('list.item.edit')}
            </Button>
          )}
          {canRemove && (
            <RemoveButton size='sm' onConfirm={mutate} loading={isPending} />
          )}
        </div>
      )}
    </div>
  );
};
