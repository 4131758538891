import { BlogVisibility } from '@admin/features/api/generated';
import { cn } from '@admin/utils/cn';

type BlogVisibilityProps = {
  visibility?: BlogVisibility;
};

const getBlogVisibilityStyles = (visibility: BlogVisibility): string => {
  switch (visibility) {
    case BlogVisibility.INTERNAL:
      return 'bg-yellow-100 text-yellow-800 dark:text-yellow-300 border border-yellow-300';
    case BlogVisibility.PUBLIC:
      return 'bg-gray-100 text-gray-800 dark:text-gray-400 border-gray-500';
  }
};

export const BlogVisibilityBadge: React.FC<BlogVisibilityProps> = ({
  visibility,
}) => {
  const styles = cn(
    'text-xs font-medium px-2.5 py-0.5 rounded border dark:bg-gray-700',
    visibility && getBlogVisibilityStyles(visibility)
  );

  return <span className={styles}>{visibility?.toLowerCase()}</span>;
};
